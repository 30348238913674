
import { defineComponent, ref, computed } from 'vue'
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import Api from '../views/Api'
import { onMounted } from 'vue'

export default defineComponent({
    name: "Home",
    components: {Footer, NavBar},
    setup() {
        const users = ref<any>([])
        let filteredUsers = ref<any>([])
        let selected = ref<string>('')

        const handleClick = (status:string) => {
            selected.value = status
        }
        const getUsers = () => {
            try{
                Api.axios_instance.get(Api.baseUrl+'api/v1/list-users-profile')
                .then(res => {
                    users.value = res.data
                    console.log(users.value);
                    
                })
            }catch(e){
                console.log("Error Occured");
                
            }
        }
        /* Filter Users based on verification status */
        filteredUsers = computed(() => {
            return users.value.filter((user:any) => user.transaction_status.includes(selected.value))
        })
        /* End of Filter */
        onMounted(() => {
            getUsers()
        })
        return {getUsers, users, filteredUsers, selected, handleClick}
    },
})
