
import { defineComponent, ref, onMounted, computed } from 'vue'
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import Api from './Api.js'
import { useRoute } from 'vue-router'
import Transaction from './types/History'
import Transactions from '../components/Transactions.vue'
import { useStore } from 'vuex'

export default defineComponent({
    name: "Home",
    components: { Footer, NavBar, Transactions },
    setup() {
        const transactions = ref<Transaction[]>([])
        let filteredTrades = ref<Transaction[]>([])
        const route = useRoute()
        const store = useStore()
        const users = ref([])
        const total_transacted = ref<number>(0)
        const selected = ref<string>('')
        let mycelium_transaction_amount = ref<any>()
        let paid_naira_amount = ref<any>()

        /* Getting all transaction details from the server */
        const getTransactions = async () => {
            try{ 
                await  Api.axios_instance.get(Api.baseUrl+'api/v1/list-all-transactions')
                    .then(res => { 
                        let transacted_amount = 0
                        const all_transactions = transactions.value = res.data
                        store.commit('storeTransactions', {all_transactions:res.data})
                        all_transactions.forEach((transaction: { naira_amount: number, transaction_status:string }) => {
                            if (transaction.transaction_status  === '2' || transaction.transaction_status === '7'){
                                transacted_amount += transaction.naira_amount}
                        })
                        total_transacted.value = transacted_amount
                    })
            }catch(e){
                alert("There's an error, please contact admin")
            }
        }
        
        /* This function gets all users from the database */
        const getAllUsers = async () => {
            try{
                await Api.axios_instance.get(Api.baseUrl+'api/v1/list-users')
                .then(res => {
                    users.value = res.data
                })
            } catch(e){
                console.log("There's an error");
            }
        }
        const handleClick = (status:string) => {
            selected.value = status
        }
        /* Filter transactions */
        const pendingTransactions = computed(() => {
            return transactions.value.filter((transaction:any) => transaction.transaction_status == '1').length
        })

        const paidTransactions = computed(() => {
            return transactions.value.filter((transaction:any) => transaction.transaction_status == '2' || transaction.transaction_status == '7').length
        })

        filteredTrades = computed(() => {
            return transactions.value.filter((transaction:any) => transaction.transaction_status.includes(selected.value)).reverse()
            
        })

        onMounted(() => {
            getTransactions()
            getAllUsers()
         })

        return {getTransactions, transactions, getAllUsers, users, total_transacted, selected, filteredTrades,
                pendingTransactions, paidTransactions, handleClick}
    },
})
