
import { defineComponent,ref } from 'vue'
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import Api from './Api'
import {useRouter} from 'vue-router'

export default defineComponent({
    name: "ChangePassword",
    components: {Footer, NavBar},
    setup() {
        const confirm_pass = ref<any>('')
        const new_pass = ref<any>('')
        const current_pass = ref <any>('')
        const router = useRouter()
        
        const changePassword = () => {
        if(new_pass.value === confirm_pass.value){
            let formData = {
                new_password: new_pass.value,
                re_new_password: confirm_pass.value,
                current_password: current_pass.value
            }
            try{ Api.axios_instance.post(Api.baseUrl+'auth/users/set_password/', formData)
                .then(res=> {
                    alert("Password Changed Successfully")
                    router.push({path:'/'})
                })
            } catch(e){
                alert("Current Password not Correct")
            }
        }
            else{
                alert("Password Mismatch")
            }
        }

        return{new_pass, confirm_pass, current_pass, changePassword}
    },
})
