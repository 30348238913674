
    import { defineComponent, ref } from 'vue'
    import NavBar from '../components/NavBar.vue'
    import Footer from '../components/Footer.vue'
    import Api from './Api'
    import { useStore } from 'vuex'
    import Coin from './types/Coin'
    import { useRoute, useRouter } from 'vue-router'
    import { onMounted } from 'vue'

    
    export default defineComponent({
        name: "CreateCoin",
        components: {Footer, NavBar},
        setup() {
            const store = useStore()
            const route = useRoute()
            const router = useRouter()
            const id = route.params.reference
            const coin = ref({
                coin_name:'' as string,
                coin_description: '' as string,
                buy_rate: 0 as any,
                sell_rate: 0 as any,
                coin_status: false as any,
                minimum_buy_limit: 0 as any,
                minimum_sell_limit: 0 as any,
                maximum_buy_limit: 0 as any,
                maximum_sell_limit: 0 as any,
                coin_short_code: '' as string,
                coin_sell_status: false as any,
                coin_buy_status: false as any,
                first_address: '' as string,
                second_address: '' as string,
                third_address: '' as string,
                fourth_address: '' as string,
                fifth_address: '' as string,
                has_networks: false as any,
                confirmation_fee: '' as string
            })
            const coinImgInput = ref<any>(null);
            const uploadImg = () => {
                if(coinImgInput.value){
                    coinImgInput.value = coinImgInput.value.files[0];
                }
            };
            const createCoin = async () => {
                const formData = new FormData()
                    formData.append("coin_name", coin.value.coin_name)
                    formData.append("coin_description", coin.value.coin_description)
                    formData.append("buy_rate", coin.value.buy_rate)
                    formData.append("sell_rate", coin.value.sell_rate)
                    formData.append("coin_status", coin.value.coin_status)
                    formData.append("minimum_buy_limit", coin.value.minimum_buy_limit)
                    formData.append("maximum_buy_limit", coin.value.maximum_buy_limit)
                    formData.append("minimum_sell_limit", coin.value.minimum_sell_limit)
                    formData.append("maximum_sell_limit", coin.value.maximum_sell_limit)
                    formData.append("short_code", coin.value.coin_short_code)
                    formData.append("buy_active_status", coin.value.coin_buy_status)
                    formData.append("sell_active_status", coin.value.coin_sell_status)
                    formData.append("first_address", coin.value.first_address)
                    formData.append("second_address", coin.value.second_address)
                    formData.append("third_address", coin.value.third_address)
                    formData.append("fourth_addres", coin.value.fourth_address)
                    formData.append("fifth_address", coin.value.fifth_address)
                    formData.append("has_networks", coin.value.has_networks)
                    formData.append("confirmation_fee", coin.value.confirmation_fee)
                    if(coinImgInput.value.type == 'image/jpeg'){
                        formData.append("image", coinImgInput.value )
                    }
                    
                try {
                    await Api.axios_instance.post(Api.baseUrl+('api/v1/create-coin'), formData)
                    .then(res => {
                        router.push({path:'/rates'})
                        alert("Coin Has Been Created")
                    })
                }catch(e){
                    console.log(e);
                    alert("An error occured, please contact admin")
                }
            }

         
        return {coin, createCoin, uploadImg, coinImgInput}
    },
})
