
import { defineComponent, ref } from 'vue'
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import Coin from './types/Coin'
import Api from './Api'
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import router from '@/router'

export default defineComponent({
    name: "Rate",
    components: { Footer, NavBar},
    setup() {
        const rates = ref<Coin[]>([])
        const buyCoinToEdit = ref<string>("")
        const sellCoinToEdit = ref<string>("")
        const feeId = ref<string>('')
        const feeEditVal = ref<any>('')
        const rateEditVal = ref<any>({
            buy_rate: '' as string,
            sell_rate: '' as string
        })
        const store = useStore()
        let adminEmail = ref<any>('')

        const getAdminEmail = () => {
            adminEmail.value =  sessionStorage.getItem('email')
        }
        
        /* Get all coins from the database */
        const getCoins = async() => {
        try {
            await  Api.axios_instance.get(Api.baseUrl+'api/v1/list-coin')
                .then(res => {
                    rates.value = res.data
                    store.commit('storeCoins', {all_coin:res.data})
                    })
        } catch(e){
            console.log("There's an error");
        }
    }

    // Set Edit Coin
    const setBuyToEdit = (id: string, buy_rate:number, sell_rate:number) => {
        buyCoinToEdit.value = id;
        rateEditVal.value.buy_rate = buy_rate;
        rateEditVal.value.sell_rate = sell_rate
    };

    const setFeeToEdit = (id:string) => {
        console.log(id);
        feeId.value = id
    }
      
    const setSellToEdit = (id:string, buy_rate:number, sell_rate:number) => {
        sellCoinToEdit.value = id
        rateEditVal.value.buy_rate = buy_rate;
        rateEditVal.value.sell_rate = sell_rate
    }

    const updateRate = async (id:string, type:string) => {
        let formData;
        if (type == 'sell' || type == "buy"){
         formData = {
                buy_rate: rateEditVal.value.buy_rate,
                sell_rate:rateEditVal.value.sell_rate
            }
        }else if(type="fee"){
            // console.log(feeEditVal);
            
            let fee = feeEditVal.value.toString()
            console.log(fee);
            
            formData = {
                confirmation_fee: fee
            }
        }
       
        try {
                await Api.axios_instance.patch(Api.baseUrl+('api/v1/update-coin/'+id), formData)
                .then(res => {
                    router.push({path:'/rates'})
                    if(type == "buy"){
                        buyCoinToEdit.value = ""
                    }else if(type == 'sell'){
                        sellCoinToEdit.value = ""
                    }else{
                        feeEditVal.value = ""
                        feeId.value = ""
                    }
                    getCoins()
                })
            }catch(e){
               alert("An error occured, pleae contact admin")
            }
    }
    onMounted (() => {
        getCoins();
        getAdminEmail()
    })

    return {getCoins, rates, adminEmail, sellCoinToEdit, buyCoinToEdit, feeEditVal,
         rateEditVal, feeId,  setBuyToEdit, setSellToEdit, updateRate, setFeeToEdit }
    },

})
