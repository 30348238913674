
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router';

export default defineComponent({
    name: "NavBar",
    setup() {
        const router = useRouter()
        const logout = () => {
            sessionStorage.clear();
            window.localStorage.clear();
            router.push({path:'/sign-in'})
        }
        let adminEmail = ref<any>('')
        const getAdminEmail = () => {
            adminEmail.value = sessionStorage.getItem('email')
        }
        onMounted(() => {
          getAdminEmail()
        })
        return{logout, adminEmail}
    },
})
