
import { defineComponent, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { toRefs } from 'vue'
import {ref} from 'vue'  
import Api from './Api.js'
import { booleanLiteral } from '@babel/types'


export default defineComponent({
    name: 'Signin',
    setup() {
        const password = ref<string>('')
        const email = ref<string>('')
        const passwordToggle = ref<boolean>(false)
        let loading = ref<boolean>(false)
        // let isAuthenticated =  ref<any>(true)
        let isAuthenticated:boolean = true;
        const router = useRouter()
       
        const callApi = async () => {
             const formData = {email: email.value, password:password.value}
            
             try{
                    await  Api.axios_instance.post(Api.baseUrl+'auth/jwt/create/', formData)
                    .then(res => {
                        const access = res.data.access
                        sessionStorage.setItem('access', access)
                        sessionStorage.setItem('isAuthenticated', 'true')
                        sessionStorage.setItem('email', email.value)
                        router.push("/")
                    })
                } catch(e){
                    alert("Email or Password not correct")
                }
                finally{
                    () => {
                        loading.value = false 
                    }
                }
            }

            const passwordToggleHandler = () => {
                passwordToggle.value = !passwordToggle.value
            }
        return {callApi, loading, password, email, passwordToggle, passwordToggleHandler}
    },
})
