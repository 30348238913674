
import { defineComponent, ref } from 'vue'
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import Api from './Api'
import { useRoute, useRouter } from 'vue-router'
import { onMounted } from 'vue'

export default defineComponent({
    name: "VerifyUser",
    components: {Footer, NavBar},
    setup(){
        const route = useRoute()
        const router = useRouter()
        const reference = route.params.reference
        let userData = ref<any>({})
        let id = ref<string>('')

        const User = async () => {
            try{
                Api.axios_instance.get(Api.baseUrl+('api/v1/profile/get/'+reference))
                .then(res => {
                    userData.value = res.data
                })
            }
            catch{
                console.log("There's an error, please contact admin");
            }
        }

        const approval = async (status:string) => {
            try{
                Api.axios_instance.patch(Api.baseUrl+('api/v1/id-approval-dissaproval/'+reference), {transaction_status:status})
                .then(res => {
                    alert("You request has been processed")
                    router.push('/users')
                })
            }catch{
                console.log("There's an error please contact admin");
            }
        }

        onMounted(() => {
            User()
        })
        return {userData, approval, id}
    }
})
