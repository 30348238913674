
    import {defineComponent, ref} from 'vue'
    import NavBar from '../components/NavBar.vue'
    import Footer from '../components/Footer.vue'
    import Api from './Api'
    import { useRoute, useRouter } from 'vue-router'
    import { onMounted } from 'vue'

    export default defineComponent({
        name: "Bonus",
        components: {Footer, NavBar},
        setup() {
            const route = useRoute

            const bonusData = ref({
                name: '' as string,
                amount: '' as string,
                code: '' as string,
                status: false as boolean
            })
            const all_bonus = ref<any>([])
            const getBonus = () => {
                Api.axios_instance.get(Api.baseUrl+'api/v1/list-campaign')
                .then(res => {
                    all_bonus.value = res.data[0]
                    bonusData.value.name = res.data[0].name
                    bonusData.value.code = res.data[0].code
                    bonusData.value.amount = res.data[0].amount
                    bonusData.value.status = res.data[0].status
                })
            }

            const updateBonus = () => {
                const formData = {
                    name: bonusData.value.name,
                    amount: bonusData.value.amount,
                    status: bonusData.value.status,
                    code: bonusData.value.code
                }
                Api.axios_instance.patch(Api.baseUrl+'api/v1/update-campaign/1', formData)
                .then(res => {
                    alert("Campaign Updated Successfully")
                    console.log(res.data);
                    getBonus()
                })
            }
            onMounted( () => {
                getBonus()
            })

            return {getBonus, all_bonus, updateBonus, bonusData}
        },
    })
