
import { defineComponent, ref } from 'vue'
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import { useStore } from 'vuex'
import Api from './Api'
import { onMounted } from 'vue'

export default defineComponent({
    name: "Testimonials",
    components: {Footer, NavBar},
    setup() {
        const testimonials = ref<any>([])
        const store = useStore()
       
        /* Get all coins from the database */
        const getTestimonials = async() => {
            try {
                await  Api.axios_instance.get(Api.baseUrl+'api/v1/list-testimonials')
                    .then(res => {
                        testimonials.value = res.data
                        store.commit('storeTestimonials', {testimonials_list:res.data})
                    })
            } catch(e){
                console.log("There's an error");
            }
        }
        const updateTestimonial = (params:any) => {
            try {
                let formData = {
                    status: !params.status
                }
                Api.axios_instance.patch(Api.baseUrl+'api/v1/update-testimonial/'+params.reference, formData)
                .then(res => {
                    getTestimonials()
                    alert("Testimonial Has Been Updated")
                })
            }catch(e){
                console.log("Error Occured");
            }
        }
        onMounted(() =>{
            getTestimonials()
        })
    return {getTestimonials, testimonials, updateTestimonial}
    },
})
