
import { defineComponent, PropType } from 'vue'
import History from '../views/types/History'

export default defineComponent({
    props: {
        transactions:{
            required: true,
            type: Array as PropType<History[]>
        },
        filteredTrades: {
            required: true,
            type: Array as PropType<History[]>
        }
    },
    setup() {
        return {}
    },
})
