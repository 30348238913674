
import { defineComponent, onMounted, ref } from 'vue';
import NavBar from '../components/NavBar.vue';
import Footer from '../components/Footer.vue'
import Api from './Api'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
    name: "EditNetwork",
    components: {Footer, NavBar},
    setup() {
        const store = useStore()
        const route = useRoute()
        const id = route.params.id
        const networkName = route.params.network
        const coin = route.params.coin

        const network = ref({
            coin: 0 as number,
            network_name: '' as string,
            first_address: '' as string,
            second_address: '' as string,
            third_address: '' as string,
            all_network:'' as string,
        })
        const allCoin = ref<any>(store.state.all_coin)

        const editNetwork = (e:any) => {
            e.preventDefault()
            if(network.value.all_network !== ""){
                let items:string[] = []
                network.value.all_network.split(',').map((item:any) => {
                    items.push(item.trim())
                    if(items.length == 1){
                        network.value.first_address = items[0]
                    }else if(items.length == 2){
                        network.value.second_address = items[1]
                    }else if(items.length == 3){
                        network.value.third_address = items[2]
                    }
                })
            }
            const formData = {
                network_name: network.value.network_name,
                first_address: network.value.first_address,
                second_address: network.value.second_address,
                third_address: network.value.third_address
            }
            Api.axios_instance.patch(Api.baseUrl+('api/v1/update-network/'+id), formData)
            .then(res => {
                alert("Network Has Been Updated")
            })
        }

        const getNetwork = () => {
            Api.axios_instance.get(Api.baseUrl+'api/v1/retrieve-network/'+id)
            .then(res => {
                network.value.first_address = res.data.first_address
                network.value.second_address = res.data.second_address
                network.value.third_address = res.data.third_address
                network.value.network_name = res.data.network_name
            })
        }

        onMounted(() => {
            getNetwork()
        })
        return{network, editNetwork, allCoin, networkName, coin}
    },
})
