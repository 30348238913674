
import { defineComponent } from 'vue'
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
export default defineComponent({
    name: "History",
    components: {Footer, NavBar},
    setup() {
        
    },
})
