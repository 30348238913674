
import { defineComponent, ref } from 'vue';
import NavBar from '../components/NavBar.vue';
import Footer from '../components/Footer.vue'
import Api from './Api'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
    name: "createNetwork",
    components: {Footer, NavBar},
    setup() {
        const store = useStore()
        const route = useRoute()
        const router = useRouter()

        const network = ref({
            coin: 0 as number,
            network_name: '' as string,
            first_address: '' as string,
            second_address: '' as string,
            third_address: '' as string,
        })
        const allCoin = ref<any>(store.state.all_coin)

        const createNetwork = () => {
            const formData = {
                coin: network.value.coin,
                network_name: network.value.network_name,
                first_address: network.value.first_address,
                second_address: network.value.second_address,
                third_address: network.value.third_address
            }
            Api.axios_instance.post(Api.baseUrl+('api/v1/create-network'), formData)
            .then(res => {
                alert("Network Has Been Created")
                network.value.network_name = ''
                network.value.first_address = ''
                network.value.second_address = ''
                network.value.third_address = ''
            })
        }
        return{network, createNetwork, allCoin}
    },
})
